import { initializeApp } from 'firebase/app';
import {
  AuthError,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  getAuth,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from 'firebase/auth';
import { Value, fetchAndActivate, getRemoteConfig, getValue } from 'firebase/remote-config';
import React, { createContext } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { toast } from 'react-toastify';
import { env } from 'src/env';
import store from 'src/store/store';
import { useUserAuthStore } from 'src/store/userAuth/userStore';
import { AuthContextInterface } from '../../context/AuthContextInterface';
import { resetUser } from '../../utils/resetUser';
import firebaseConfig from './config/firebaseConfig';
import * as ERROR_MESSAGES from './config/firebaseErrorMessages';
import remoteConfigDefaults from './remoteConfigDefaults/remote_config_defaults.json';

const app = initializeApp(firebaseConfig.config);

// #region Firebase Remote Config
const remoteConfig = getRemoteConfig(app);

remoteConfig.defaultConfig = remoteConfigDefaults;

remoteConfig.settings.minimumFetchIntervalMillis = parseInt(
  env.REACT_APP_FIREBASE_REMOTE_CONFIG_FETCH_INTERVAL
);

/**
 * Fetches and activates the remote configuration.
 *
 * @remarks
 * This function should be called in the App.tsx file to fetch and activate the remote configuration.
 */
export const fetchAndActivateConfig = async () => {
  try {
    await fetchAndActivate(remoteConfig);
  } catch (error) {
    toast.error('Error fetching remote config');
  }
};

/**
 * Retrieves the configuration value for the specified feature flag key.
 * This function is used in the FeatureFlagGuard component.
 *
 * @param key - The key of the feature flag.
 * @returns The value of the feature flag.
 */
export const getConfigValue = (key: FlagKeys): Value => {
  return getValue(remoteConfig, key);
};

export type FlagKeys = keyof typeof remoteConfigDefaults;

// #region Firebase Auth
export const FirebaseAuthContext = createContext<AuthContextInterface>({
  user: null,
  loading: false,
  error: undefined,
  isAuthenticated: false,
  getTokens: () => Promise.resolve({ accessToken: null, refreshToken: null }),
  loginWithEmailAndPassword: () => Promise.resolve(),
  loginWithGoogle: () => Promise.resolve(),
  register: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  refreshToken: () => Promise.resolve(),
  verifyEmail: () => Promise.resolve(),
  resetPassword: () => Promise.resolve(),
  emailVerified: false,
  authHook: undefined,
});

interface AuthProviderProps {
  children: React.ReactNode;
}

export const FirebaseAuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const auth = getAuth(app);
  const [user, loading, error] = useAuthState(auth);

  const userActions = useUserAuthStore((state) => state);

  const loginWithEmailAndPassword = async (email: string, password: string) => {
    try {
      const firebaseUser = await signInWithEmailAndPassword(auth, email, password);
      userActions.setUser(firebaseUser.user);
      userActions.setUserStatus('authenticated');
      const tokens = await getTokens();
      userActions.setAccessToken(tokens?.accessToken ?? '');
      userActions.setRefreshToken(tokens?.refreshToken ?? '');
    } catch (err: unknown) {
      const authErr = err as AuthError;
      handleError(authErr);
    }
  };

  const loginWithGoogle = async () => {
    try {
      const provider = new GoogleAuthProvider();
      await signInWithPopup(auth, provider);
    } catch (err: unknown) {
      const authErr = err as AuthError;
      handleError(authErr);
    }
  };

  const register = async (email: string, password: string) => {
    try {
      await createUserWithEmailAndPassword(auth, email, password);
    } catch (err: unknown) {
      const authErr = err as AuthError;
      handleError(authErr);
    }
  };

  const logout = async () => {
    try {
      logoutFirebase();
      userActions.resetUserState();
    } catch (err: unknown) {
      const authErr = err as AuthError;
      handleError(authErr);
    }
  };

  const refreshToken = async () => {
    try {
      await refreshFirebaseToken();
    } catch (err: unknown) {
      const authErr = err as AuthError;
      handleError(authErr);
    }
  };

  const getTokens = async () => {
    const currentUser = auth.currentUser;
    try {
      return {
        accessToken: (await currentUser?.getIdToken(true)) ?? null,
        refreshToken: currentUser?.refreshToken ?? null,
      };
    } catch (err: unknown) {
      const authErr = err as AuthError;
      handleError(authErr);
      return null;
    }
  };

  const verifyEmail = async () => {
    const currentUser = auth.currentUser;
    if (currentUser) {
      try {
        await sendEmailVerification(currentUser);
      } catch (err: unknown) {
        const authErr = err as AuthError;
        handleError(authErr);
      }
    }
  };

  const resetPassword = async (email: string) => {
    try {
      await sendPasswordResetEmail(auth, email);
    } catch (err: unknown) {
      const authErr = err as AuthError;
      handleError(authErr);
    }
  };

  const getFirebaseErrorMessage = (code: AuthError['code']) => {
    let message = null;
    switch (code) {
      case 'auth/user-not-found':
        message = ERROR_MESSAGES.USER_NOT_FOUND;
        break;
      case 'auth/email-already-exists':
        message = ERROR_MESSAGES.EMAIL_ALREADY_EXIST;
        break;
      case 'auth/too-many-requests':
        message = ERROR_MESSAGES.EMAIL_ALREADY_EXIST;
        break;
      case 'auth/internal-error':
        message = ERROR_MESSAGES.INTERNAL_ERROR;
        break;
      case 'auth/invalid-credential':
        message = ERROR_MESSAGES.INVALID_CREDENTIAL;
        break;
      case 'auth/invalid-login-credentials':
        message = ERROR_MESSAGES.INVALID_CREDENTIAL;
        break;
      case 'auth/invalid-email':
        message = ERROR_MESSAGES.INVALID_CREDENTIAL;
        break;
      case 'auth/invalid-password':
        message = ERROR_MESSAGES.INVALID_CREDENTIAL;
        break;
      default:
        message = ERROR_MESSAGES.DEFAULT_MESSAGE;
        break;
    }
    return message;
  };

  const handleError = (error: AuthError) => {
    toast.error(getFirebaseErrorMessage(error.code));
  };

  const emailVerified = auth.currentUser ? auth.currentUser.emailVerified : false;

  const isAuthenticated = user ? true : false;

  const authHook = useAuthState(auth);

  return (
    <FirebaseAuthContext.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        error,
        getTokens,
        loginWithEmailAndPassword,
        loginWithGoogle,
        register,
        logout,
        refreshToken,
        verifyEmail,
        resetPassword,
        emailVerified,
        authHook,
      }}
    >
      {children}
    </FirebaseAuthContext.Provider>
  );
};

export const refreshFirebaseToken = async () => {
  const userActions = useUserAuthStore((state) => state);
  try {
    const auth = getAuth();
    const currentUser = auth.currentUser;
    if (!currentUser) {
      throw new Error('Cannot get new token');
    }

    const accessToken = await currentUser?.getIdToken(true);
    const refreshToken = currentUser?.refreshToken;

    userActions.setUser(currentUser);
    userActions.setUserStatus('authenticated');
    userActions.setAccessToken(accessToken);
    userActions.setRefreshToken(refreshToken);

    return {
      accessToken,
      refreshToken,
    };
  } catch (error) {
    return {
      refreshToken: null,
      accessToken: null,
    };
  }
};

export const logoutFirebase = async () => {
  const auth = getAuth();
  await signOut(auth);
  store.dispatch(resetUser());
  window.location.href = '/auth/login';
};
